'use strict';

import Icons from "IconLib";
/**
 * Helper class for working with the new MessageCenter
 * @constructor
 */


window.MessageCenterHelper = function MessageCenterHelper() {
    return {
        _currentStructure: null,

        get currentStructure() {
            // It is important to clone the current structure the prevent the currentStructure from being modified
            return cloneObject(this._currentStructure);
        },

        set currentStructure(structure) {
            this._currentStructure = structure;
        },

        isAvailable: function () {
            return SandboxComponent.isMessageCenterAvailable();
        },
        isLimitedUser: function () {
            return this._currentStructure && this._currentStructure.isLimitedUser;
        },

        /**
         * Severity Enum
         * @type {{INFO: number, WARNING: number, IMPORTANT: number, CRITICAL: number}}
         */
        SEVERITY_LEVEL: {
            INFO: 1,
            // just an info, nothing to bother about
            WARNING: 2,
            // a warning, should be taken care of, but no immediate action required
            IMPORTANT: 3,
            // important message, this has to be taken care of
            CRITICAL: 4 // critical message, this has to be taken care of right away!

        },
        KNOWN_ENTRY_ID: {
            REBOOT: 503,
            OLD_SYSTEM_MESSAGE: 506,
            MS_UPDATE: 524,
            INIT_PRESENTATION: 1028,
            DEVICE_UPDATE: 519,
            CALLER_EXPIRED: 530,
            NO_ACTIVE_CALLER: 531,
            WEATHER_EXPIRED: 514,
            NO_ACTIVE_WEATHER: 532,
            INTERCOM_SABOTAGE: 1127
        },
        KNOWN_ACTION_ID: {
            ACKNOWLEDGE: 1005,
            JUMP_TO_CONTROL: 1007
        },

        /**
         * The key represents the actionId of the action
         * All known actions require specific functions to be executed
         */
        KNOWN_ACTIONS: {
            /*0: function(action) {
                if (!action) {
                    console.warn("No action has been passed!");
                    return;
                }
                 alert("This is an example action!");
            }*/
        },

        /**
         * Checks, if the provided action is known and requires special execution
         * @param action
         * @return {boolean}
         */
        isActionKnown: function isActionKnown(action) {
            return !!this.KNOWN_ACTIONS[action.actionId];
        },

        /**
         * Executes the give
         * @param action
         * @return {*}
         */
        executeKnownAction: function executeKnownAction(action) {
            var actionFunction = this.KNOWN_ACTIONS[action.actionId];

            if (typeof actionFunction === "function") {
                return actionFunction(action);
            } else {
                console.warn("Action is not known!");
            }
        },

        /**
         * Will return a description of the systemstate or null if the description can't be build
         * The description has the following formula: __cnt__ __severity__ active
         * @return {*}
         */
        getDescription: function getDescription() {
            // check if the message center structure is correct/available
            if (!this._currentStructure || !this._currentStructure.entries.length) {
                return null;
            }

            var severityString = "",
                // Sort to get the most important active entries first
                // It is important to clone the current structure the prevent the currentStructure from being modified
                severitySortedActiveEntries = cloneObject(this._currentStructure).entries.sort(function (a, b) {
                    return b.severity - a.severity;
                }).filter(function (entry) {
                    // This filters out the inactive entries
                    return !entry.setHistoricAt;
                }),
                // Ge the entries with a matching severity
                sameSeverityEntries,
                cntObj;

            if (!severitySortedActiveEntries.length) {
                return null;
            }

            sameSeverityEntries = this._currentStructure.entries.filter(function (entry) {
                return entry.severity === severitySortedActiveEntries[0].severity && !entry.setHistoricAt;
            });
            cntObj = {
                count: sameSeverityEntries.length
            };

            if (sameSeverityEntries.length) {
                switch (sameSeverityEntries[0].severity) {
                    case MessageCenterHelper.SEVERITY_LEVEL.INFO:
                        severityString = _("message-center.severity-level.info", cntObj);
                        break;

                    case MessageCenterHelper.SEVERITY_LEVEL.WARNING:
                        severityString = _("message-center.severity-level.warning", cntObj);
                        break;

                    case MessageCenterHelper.SEVERITY_LEVEL.IMPORTANT:
                        severityString = _("message-center.severity-level.important", cntObj);
                        break;

                    case MessageCenterHelper.SEVERITY_LEVEL.CRITICAL:
                        severityString = _("message-center.severity-level.critical", cntObj);
                        break;
                }

                return _("message-center.x-active-entries", {
                    count: cntObj.count,
                    severity: severityString
                });
            } else {
                return null;
            }
        },

        /**
         * Opens the correct entry with the given entryUuid
         * @param entryUuid
         */
        getMessageCenterEntryWithEntryUuid: function getMessageCenterEntryWithEntryUuid(entryUuid) {
            if (this.currentStructure && this.currentStructure.entries.length) {
                return this.currentStructure.entries.find(function (entry) {
                    return entry.entryUuid === entryUuid;
                });
            }
        },

        /**
         * Returns the color for the given entry based on the severity level
         * @param entry
         * @param checkConfirmed Weather or not the confirmedAt property of the entry should be checked for (No color if set)
         * @return {*}
         */
        getColorForSeverityEntry: function getColorForSeverityEntry(entry, checkConfirmed) {
            let color;

            if (entry.setHistoricAt) {
                color = Color.STATE_INACTIVE_B;
            } else {
                switch (entry.severity) {
                    case this.SEVERITY_LEVEL.WARNING:
                        color = window.Styles.colors.orange;
                        break;

                    case this.SEVERITY_LEVEL.IMPORTANT:
                    case this.SEVERITY_LEVEL.CRITICAL:
                        color = window.Styles.colors.red;
                        break;

                    case this.SEVERITY_LEVEL.INFO:
                        color = window.Styles.colors.blue;
                        break;

                    default:
                        color = Color.ICON_A;
                }

                if (checkConfirmed && entry.confirmedAt) {
                    color = Color.WHITE;
                }
            }

            return color;
        },

        /**
         * Returns the tint color for the cells of the given entry based on the severity level
         * Only active, non-historic entries are tinted
         * @param entry
         * @param checkConfirmed Weather or not the confirmedAt property of the entry should be checked for (No color if set)
         * @return {*}
         */
        getTintColorForSeverity: function getTintColorForSeverity(entry, checkConfirmed) {
            // Only tint active entries
            if (entry.setHistoricAt) {
                return;
            }

            var color;

            switch (entry.severity) {
                case this.SEVERITY_LEVEL.CRITICAL:
                    color = window.Styles.colors.red;
                    break;

                default:
                    // only critical entries have a tint color.
                    break;
            }

            if (checkConfirmed && entry.confirmedAt) {
                color = null;
            }

            return color;
        },

        /**
         * Returns the icon for the given entry based on the severity level
         * @param entry
         * @param circled
         * @return {*}
         */
        getIconForSeverityEntry: function getIconForSeverityEntry(entry, circled = false, legacy = false) {
            var legacyIcon,
                legacyPostfix = "",
                reactIcon,
                reactPostfix = ""

            if (circled) {
                legacyPostfix = "_CIRCLED";
                reactPostfix = "Circled"
            }

            switch (entry.severity) {
                case this.SEVERITY_LEVEL.WARNING:
                    legacyIcon = Icon.MESSAGE_CENTER["WARNING" + legacyPostfix];
                    reactIcon = Icons[`SystemStateWarning${reactPostfix}`];
                    break;

                case this.SEVERITY_LEVEL.IMPORTANT:
                case this.SEVERITY_LEVEL.CRITICAL:
                    legacyIcon = Icon.MESSAGE_CENTER["ERROR" + legacyPostfix];
                    reactIcon = Icons[`SystemStateError${reactPostfix}`];
                    break;

                default:
                    legacyIcon = Icon.MESSAGE_CENTER["INFO" + legacyPostfix];
                    reactIcon = Icons[`SystemStateInfo${reactPostfix}`];
            }

            return legacy ? legacyIcon : reactIcon;
        },

        /**
         * Returns the formatted time of a given entry
         * @param entry The entry to get the time string from
         * @param [format] Optional format, DateType.DateTextAndTimeNoYear will be used, if no format is specified
         */
        getFormatedDate: function getFormatedDate(entry, format) {
            var lastOccurence = entry.timestamps[entry.timestamps.length - 1];

            if (format) {
                format = DateType.DateTextAndTimeNoYear;
                return moment.unix(lastOccurence).format(format);
            } else {
                return LxDate.formatPastDateDynamic(lastOccurence);
            }
        },

        /**
         * Returns the location (room name) if the entry has the roomUuid property,
         * or null if roomUuid doesn't exist, or the room can't be found
         * @param entry
         * @return {String | null}
         */
        getLocationForEntry: function getLocationForEntry(entry) {
            var locationText = null,
                room;

            if (entry.roomUuid) {
                room = ActiveMSComponent.getStructureManager().getGroupByUUID(entry.roomUuid, GroupTypes.ROOM);
                locationText = room ? room.name : null;
            }

            if (entry.installationPlace) {
                locationText = locationText ? locationText + ", " + entry.installationPlace : entry.installationPlace;
            }

            return locationText;
        },

        /**
         * Finds all non historic and not confirmed (Except UI locking) entries related to the given sourceUuid sorted
         * after their severity
         * @param sourceUuids The sourceUuids of the entries (Controls uuidAction for now)
         * @note sourceUuids may be a string or an array of strings
         * @return {Array}
         */
        findActiveEntriesWithSourceUuid: function findActiveEntriesWithSourceUuid(sourceUuids) {
            var entries = [];

            if (typeof sourceUuids === "string") {
                sourceUuids = [sourceUuids];
            }

            if (this._currentStructure) {
                entries = this._currentStructure.entries.filter(function (entry) {
                    // The affectedUuids hold all uuids affected by this entry, such uuids can be from devices, controls, rooms, users,...
                    // Just check if the given sourceUuid is part of the affectedUuids
                    return entry.affectedUuids.map(function (affectedUuid) {
                        return sourceUuids.includes(affectedUuid);
                    }).reduce(function (left, right) {
                        return !!(left | right);
                    }, false) && entry.setHistoricAt === null && ((!entry.confirmedAt || false) || entry.isVisuLocked); // Don't show any historic entries
                }); // Sort them after their severity, this makes getting the most important easier

                entries = entries.sort(function (entry1, entry2) {
                    return entry2.severity - entry1.severity;
                });
            }

            return entries;
        },
        sortEntries: function sortEntries(left, right) {
            // The last timestamp is the one when the entry occurred
            return right.timestamps[right.timestamps.length - 1] - left.timestamps[left.timestamps.length - 1];
        }
    };
}
